<template>
  <div class="map__results">
    <div class="wrapper">
      <div class="map__results__title">Találatok elérhetőség alapján:</div>

      <div class="map__results__grid">
        <div v-for="garage in garages" :key="garage.id" class="map__results__item">
          <Box :properties="garage" :showDistance="location.length > 0"></Box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Box from './Box.vue'

export default {
  computed: {
    ...mapGetters(['location', 'garages'])
  },

  components: {
    Box
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/tools.scss';
@import '../../assets/sass/layout/_wrapper.scss';

.map {
  &__results {
    padding-bottom: 30px;

    &__title {
      font-weight: 200;
      font-size: 24px;
      display: block;
      margin: 35px 0 0 0;

      span {
        font-weight: bold;
        color: $color--primary;
      }
    }

    &__grid {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      margin-right: -10px;
      margin-left: -10px;
      padding-top: 20px;
    }

    &__item {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 10px;
    }
  }
}

@media #{$mq--tablet} {
  .map {
    &__results {
      &__item {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}

@media #{$mq--mobile} {
  .map {
    &__results {
      &__title {
        font-size: 20px;
      }

      &__grid {
        display: block;
      }

      &__item {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
</style>
