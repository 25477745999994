<template>
  <div>
    <Hero></Hero>
    <Search @submitPhrase="submitPhrase"></Search>
    <SearchMap :key="submitCount" :phrase="phrase"></SearchMap>
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import Search from '../components/Search.vue'
import SearchMap from '../components/SearchMap.vue'

export default {
  components: {
    Hero,
    Search,
    SearchMap
  },

  data() {
    return {
      phrase: '',
      submitCount: 0
    }
  },

  methods: {
    submitPhrase(e) {
      this.phrase = e
      this.submitCount++
    }
  }
}
</script>
