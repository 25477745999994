import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import Home from './pages/Home.vue'

import defaultStore from './utils/store'

Vue.use(Vuex)
Vue.use(VueRouter)

Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    }
  ]
})

const store = new Vuex.Store(defaultStore)

new Vue({
  render: (h) => h(App),
  router,
  store
}).$mount('#app')
