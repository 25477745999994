<template>
  <div id="app">
    <div class="home-view">
      <div class="site-container">
        <AppHeader></AppHeader>
        <router-view></router-view>
        <AppFooter></AppFooter>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader.vue'
import AppFooter from '@/components/layout/AppFooter.vue'

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss">
@import './assets/sass/global.scss';
</style>
