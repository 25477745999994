<template>
  <div class="search">
    <div class="wrapper">
      <div class="search__promo">
        <p>Gumiabroncsa felszereltetéséhez foglaljon időpontot partnereink egyikénél!</p>
      </div>

      <div class="search__cols">
        <div class="search__links">
          <p>
            Találja meg az Önhöz legközelebbi Continental partnert!<br />Adjon meg egy helységet vagy irányítószámot!
          </p>
          <p></p>

          <div class="search__location__form">
            <span></span>
            <input
              v-model="phrase"
              type="text"
              name="phrase"
              placeholder="pl. Budaörs vagy 2040"
              @keyup.enter="submitPhrase"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phrase: ''
    }
  },

  mounted() {
    this.phrase = this.$store.state.search.phrase
  },

  methods: {
    submitPhrase() {
      this.$store.dispatch('START_SEARCH', this.phrase)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/tools.scss';
@import '../assets/sass/layout/_wrapper.scss';
@import '../assets/sass/ui/_button.scss';
@import '../assets/sass/ui/_loading.scss';

.search {
  position: relative;
  background: $color--gray-light;
  padding: 25px 0 35px;

  &__promo {
    margin-bottom: 20px;

    p {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.5;
      max-width: 740px;
    }
  }

  &__cols {
    display: flex;
    justify-content: space-between;

    > div {
      position: relative;
      flex-basis: 50%;
      padding: 0 35px 0 70px;

      &:first-child {
        padding-left: 0;
      }

      p {
        font-size: 1.05em;
        line-height: 1.4;

        small {
          font-size: 11px;
          margin-top: 15px;
        }
      }
    }
  }

  &__step {
    width: 55px;
    height: 55px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 22px;
    font-weight: bold;
    line-height: 55px;
    background: $color--primary;
    color: white;
    text-align: center;
    border: 0;
    border-radius: 50%;
  }

  &__location {
    &__form {
      width: 65%;
      display: flex;
      justify-content: space-between;
      height: $height--input;
      background: $color--white;
      border: 1px solid $color--gray-light;
      border-radius: 22px;
      box-shadow: 0 1px 1px $color--gray;
      margin-top: 15px;

      span {
        width: 24px;
        height: 28px;
        position: relative;
        top: 10px;
        left: 12px;
        display: inline-block;
        background: url(../assets/img/icon-location.svg) no-repeat;
        background-size: 100%;
      }

      input {
        flex: 1;
        font: normal 16px $font--primary;
        color: $color--gray;
        height: $height--input;
        background: none;
        border: 0;
        padding: 0 20px;
        outline: none;
      }
    }
  }

  // &__links {
  //   margin-left: 75px;
  // }
}

.btn {
  &--calendar {
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      position: relative;
      top: 6px;
      margin-right: 15px;
      background: url(../assets/img/icon-calendar.svg) no-repeat;
      background-size: 100%;
    }
  }
}

@media #{$mq--tablet} {
  .search {
    padding: 30px 0;

    &__cols {
      display: block;

      > div {
        padding: 20px 0 20px 70px;
      }
    }

    &__links {
      margin-left: 0;
    }

    &__location {
      &__form {
        width: 100%;
      }
    }
  }
}

@media #{$mq--mobile} {
  .search {
    &__promo {
      p {
        font-size: 16px;
      }
    }

    &__step {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 18px;
    }

    &__cols {
      > div {
        padding: 10px 0 10px 50px;
      }
    }
  }
}
</style>
