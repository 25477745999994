<template>
  <div class="map__results">
    <div class="map__results__map"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      mapElement: null,
      selectedWindow: null
    }
  },

  mounted() {
    this.mapElement = new window.google.maps.Map(this.$el.querySelector('.map__results__map'), {
      zoom: this.map.zoom,
      center: this.map.center,
      disableDefaultUi: true,
      mapTypeControl: false,
      fullScreenControl: false
    })

    this.addMarkers()

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setCenter') {
        this.mapElement.setCenter(this.map.center)
      }

      if (mutation.type === 'setZoom') {
        this.mapElement.setZoom(this.map.zoom)
      }

      if (mutation.type === 'setGarages') {
        this.addMarkers()
      }
    })
  },

  methods: {
    addMarkers() {
      this.garages.map((row) => {
        const marker = new window.google.maps.Marker({
          position: row.google_latlong,
          map: this.mapElement
        })

        marker.addListener('click', () => {
          this.openInfo(row.id, marker)
        })
      })
    },

    openInfo(id, marker) {
      const content = this.createInfo(id)
      const overlay = new window.google.maps.InfoWindow({ content })

      overlay.open(this.mapElement, marker)

      this.$store.commit('setCenter', marker.position)
      this.$store.commit('setZoom', 13)
    },

    createInfo(id) {
      const me = this.garages.filter((row) => {
        return row.id === id
      })
      const content = `<div style="line-height: 1.5; text-align: left; padding: 5px;">
                                <h5 style="font-size: 18px; color: #ffa500;">${me[0].title}</h5>
                                <p><b>${me[0].address}</b></p>
                                <p>${me[0].phone}</p>
                                <p><a href="http://${me[0].website}" target="_blank" rel="noopener" style="color: #000; text-decoration: underline;">${me[0].website}</a></p>
                                <p>${me[0].hours}</p>
                                <div style="display: block; margin: 15px 0;">
                                    <a class="btn btn--primary btn--full" href="http://${me[0].website}" target="_blank" rel="noopener" style="width: 100%; display: block; text-align: center; background: #ffa500; color: black; font-weight: bold; font-size: 15px; padding: 10px 0; border-radius: 22px;">Időpontfoglalás</a>
                                </div>
                            </div>`

      return content
    }
  },

  filters: {
    formatDistance(n) {
      return Math.floor(n / 1000)
    }
  },

  computed: {
    ...mapState(['map', 'garages'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/tools.scss';
@import '../../assets/sass/layout/_wrapper.scss';

.map {
  &__results {
    &__map {
      width: 100%;
      height: 800px;
      display: block;
    }
  }
}

@media #{$mq--desktop} {
  .map {
    &__results {
      &__map {
        height: 500px;
      }
    }
  }
}
</style>
