<template>
  <div class="map">
    <Tabs></Tabs>

    <div
      class="map__container"
      :class="{ 'is-loading': $store.state.search.loading }"
      :key="`render-${$store.state.search.tab}`"
    >
      <DistanceResults v-if="$store.state.search.tab === 'distance'" />
      <TimesResults v-if="$store.state.search.tab === 'times'" />
      <MapResults v-if="$store.state.search.tab === 'map'" />
    </div>
  </div>
</template>

<script>
import Tabs from './map/Tabs.vue'
import DistanceResults from './map/DistanceResults.vue'
import TimesResults from './map/TimesResults.vue'
import MapResults from './map/MapResults.vue'

export default {
  components: {
    Tabs,
    DistanceResults,
    TimesResults,
    MapResults
  },

  data() {
    return {
      isLoading: true
    }
  },

  async mounted() {
    await this.$store.dispatch('FETCH_GARAGES').then(() => {
      this.isLoading = false
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/tools.scss';
@import '../assets/sass/layout/_wrapper.scss';
@import '../assets/sass/ui/_loading.scss';

.map {
  display: block;

  &__container {
    display: block;
    position: relative;
    min-height: 600px;
  }
}

@media #{$mq--desktop} {
  .map {
    &__container {
      min-height: 500px;
    }
  }
}
</style>
