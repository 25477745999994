const https = require('https')

const config = {
  ADMIN_URL: process.env.VUE_APP_ADMIN_URL,
  API_URL: process.env.VUE_APP_API_URL,
  API_HEADERS: {
    Accept: 'application/json',
    'Accept-Language': 'hu',
    'Content-Type': 'application/json'
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyA28CTPoaYq4vH4gfmi5bU5yvDR0aTYzxw',
  MAP_DEFAULT_CENTER: { lat: 47.013364, lng: 19.2057063 },
  MAP_DEFAULT_ZOOM: 8
}

config.AXIOS_DEFAULTS = {
  baseURL: config.API_URL,
  headers: config.API_HEADERS,
  httpsAgent: new https.Agent({ rejectUnauthorized: false })
}

export default config
