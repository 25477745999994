<template>
  <div class="map__tabs">
    <ul class="wrapper">
      <li :class="{ 'is-active': $store.state.search.tab === 'times' }">
        <a href="#/" @click="switchTab('times')">Leghamarabb</a>
      </li>
      <li :class="{ 'is-active': $store.state.search.tab === 'distance' }">
        <a href="#/" @click="switchTab('distance')">Legközelebb</a>
      </li>
      <li :class="{ 'is-active': $store.state.search.tab === 'map' }">
        <a href="#/" @click="switchTab('map')">Térképes nézet</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    switchTab(tab) {
      this.$store.dispatch('SWITCH_TAB', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/tools.scss';
@import '../../assets/sass/layout/_wrapper.scss';

.map {
  &__tabs {
    display: block;
    border-bottom: 1px solid $color--gray-light;

    ul {
      display: flex;
      list-style: none;

      li {
        position: relative;
        top: 1px;

        a {
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
          color: $color--black;
          padding: 12px 20px;
          border-bottom: 3px solid transparent;
        }

        &.is-active {
          a {
            color: $color--primary;
            border-color: $color--primary;
          }
        }
      }
    }
  }
}

@media #{$mq--mobile} {
  .map {
    &__tabs {
      ul {
        display: block;

        li {
          display: block;

          a {
            display: block;
          }
        }
      }
    }
  }
}
</style>
