import Vue from 'vue'
import axios from 'axios'
import config from '../config'

const defaultStore = {
  state: {
    search: {
      tab: 'times',
      phrase: '',
      location: '',
      loading: false
    },
    map: {
      center: new window.google.maps.LatLng(47.130307, 19.203601),
      origin: new window.google.maps.LatLng(47.130307, 19.203601),
      zoom: 8
    },
    garages: []
  },

  actions: {
    FETCH_GARAGES(store) {
      return axios
        .get('/garages/ordered', config.AXIOS_DEFAULTS)
        .then((response) => {
          store.commit('setGarages', response.data)
          store.commit('sortGarages')
        })
        .catch((error) => {
          console.error(error)
        })
    },

    SWITCH_TAB(store, payload) {
      store.commit('setTab', payload)
      store.commit('sortGarages')
    },

    START_SEARCH(store, payload) {
      store.commit('setPhrase', payload)

      if (payload === '') {
        store.commit('setOrigin', config.MAP_DEFAULT_CENTER)
        store.commit('setCenter', config.MAP_DEFAULT_CENTER)
        store.commit('setZoom', config.MAP_DEFAULT_ZOOM)
      } else {
        const origin = {}
        const location = ''
        const gc = new window.google.maps.Geocoder()
        const zip = parseInt(payload)

        if (payload.length === 4 && zip === payload) {
          payload = `${payload} Magyarország`
        }

        gc.geocode({ address: payload, region: 'HU' }, (results, status) => {
          if (status === 'OK') {
            store.commit('setOrigin', results[0].geometry.location)
            store.commit('setCenter', results[0].geometry.location)
            store.commit('setZoom', 13)
            store.commit('setLocation', results[0].formatted_address)
            store.dispatch('SWITCH_TAB', 'distance')
          }
        })
      }
    }
  },

  mutations: {
    setPhrase(state, payload) {
      Vue.set(state.search, 'phrase', payload)
    },

    setTab(state, payload) {
      Vue.set(state.search, 'tab', payload)
    },

    setGarages(state, payload) {
      payload.map((row) => {
        const latlng = row.google_latlong.split(',')
        row.google_latlong = new window.google.maps.LatLng(parseFloat(latlng[0]), parseFloat(latlng[1]))
      })

      Vue.set(state, 'garages', payload)
    },

    sortGarages(state) {
      const garagesData = state.garages

      if (state.search.tab === 'times') {
        // garagesData.sort((a, b) => (a.next_time > b.next_time ? 1 : b.next_time > a.next_time ? -1 : 0)).reverse()
        Vue.set(state, 'garages', garagesData)
      }

      if (state.search.tab === 'distance' && state.search.location) {
        Vue.set(state.search, 'loading', true)
        const service = new window.google.maps.DistanceMatrixService()

        garagesData.map((row) => {
          service.getDistanceMatrix(
            {
              origins: [state.map.origin],
              destinations: [row.google_latlong],
              travelMode: 'DRIVING'
            },
            (response, status) => {
              if (status === 'OK') {
                response.rows[0].elements.forEach((respRow) => {
                  row.distance = respRow.distance.value
                  row.travel_time = respRow.duration.text
                })
              }
            }
          )
        })

        setTimeout(() => {
          garagesData.sort((a, b) => (a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0))
          Vue.set(state, 'garages', garagesData)
          Vue.set(state.search, 'loading', false)
        }, 1000)
      }
    },

    setLocation(state, payload) {
      Vue.set(state.search, 'location', payload)
    },

    setOrigin(state, payload) {
      Vue.set(state.map, 'origin', payload)
    },

    setCenter(state, payload) {
      Vue.set(state.map, 'center', payload)
    },

    setZoom(state, payload) {
      Vue.set(state.map, 'zoom', payload)
    }
  },

  getters: {
    phrase(state) {
      return state.search.phrase
    },

    location(state) {
      return state.search.location
    },

    garages(state) {
      return state.garages
    },

    map(state) {
      return state.map
    }
  }
}

export default defaultStore
