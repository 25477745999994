<template>
  <div class="box">
    <a v-if="properties.website" class="box__image" :href="formattedWebsite" target="_blank" rel="noopener">
      <img v-if="properties.image" :src="`${imageUrl}/${properties.image}`" :alt="properties.title" />
      <img v-else src="@/assets/img/default.jpg" :alt="properties.title" />
    </a>

    <div v-else class="box__image">
      <img src="@/assets/img/default.jpg" :alt="properties.title" />
    </div>

    <div class="box__body">
      <h2>{{ properties.title }}</h2>
      <p>{{ properties.address }}</p>
      <p>{{ properties.phone }}</p>

      <p>
        <a :href="formattedWebsite" target="_blank" rel="noopener">{{ properties.website }}</a>
      </p>

      <p class="box__body__hours" v-html="properties.hours"></p>

      <p v-if="properties.distance" class="box__body__distance">
        <strong>{{ properties.distance | formatDistance }} km-re</strong>
      </p>

      <p v-if="properties.next_time && $store.state.search.tab === 'times'" class="box__body__nexttime">
        Legközelebbi időpont: <b>{{ formattedNextTime }}</b>
      </p>

      <div class="box__body__submit">
        <a class="btn btn--primary btn--full" :href="formattedWebsite" target="_blank" rel="noopener"
          >Időpontfoglalás</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../config'

export default {
  filters: {
    formatDistance(n) {
      return Math.floor(n / 1000)
    }
  },

  computed: {
    formattedNextTime() {
      const dateFormat = new Intl.DateTimeFormat('hu-HU', {
        dateStyle: 'full',
        timeStyle: 'short'
      })
      return dateFormat.format(new Date(this.properties.next_time * 1e3))
    },

    formattedWebsite() {
      const address = this.properties.website
      return address.substring(0, 4) !== 'http' ? `https://${address}` : address
    }
  },

  props: {
    properties: {
      type: Object,
      default: null
    },
    showDistance: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      imageUrl: ''
    }
  },

  mounted() {
    this.imageUrl = `${config.ADMIN_URL}/uploads`
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/tools.scss';
@import '../../assets/sass/layout/_wrapper.scss';
@import '../../assets/sass/ui/_button.scss';

.box {
  display: block;
  background: $color--white;
  border: 2px solid $color--gray-light;

  &__image {
    width: 100%;
    height: 200px;
    display: block;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__body {
    padding: 20px;

    h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
      line-height: 1.5;

      a {
        color: black;
        text-decoration: underline;
      }
    }

    &__hours {
      font-size: 11px;
    }

    &__distance {
      color: $color--primary;
      font-size: 13px;
      margin-top: 10px;

      &:before {
        content: '';
        width: 9px;
        height: 13px;
        display: inline-block;
        position: relative;
        top: 2px;
        background: url(../../assets/img/icon-pin.svg) no-repeat;
        background-size: 100%;
        margin-right: 5px;
      }
    }

    &__nexttime {
      font-size: 12px;
      font-weight: bold;
      margin-top: 10px;

      b {
        display: block;
        color: $color--success;
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        top: 3px;
        background: url(../../assets/img/icon-clock.svg) no-repeat;
        background-size: 100%;
      }
    }

    &__submit {
      margin-top: 15px;

      .btn--full {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        border-radius: 22px;
      }
    }
  }
}

@media #{$mq--mobile} {
  .box {
    &__image {
      height: auto;
    }

    &__body {
      padding: 12px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}
</style>
